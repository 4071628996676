<template>
  <section class="hero">
    <div class="hero-content">
      <h1 class="hero-title">Enclave76: America's Last, Best Hope</h1>
      <p class="hero-subtitle">Citizens of the wasteland, hear the call of your true government. The Enclave stands ready to reclaim our great nation and restore the American dream.</p>
      <p class="hero-subtitle fs-3 fw-bold">Your patriotic duty awaits!</p>
      <a href="https://discord.gg/gQgtrRKefh" target="_blank" class="btn btn-primary btn-lg fw-bold" @click="trackEvent('Enlist and Serve')">Enlist and Serve</a>
    </div>
    <img class="hero-image" src="../assets/images/logo.webp" alt="Enclave Executive Command">
  </section>
</template>

<script>
export default {
  name: 'AppHeroSection',
  methods: {
    trackEvent(action) {
      try {
        if (window.gtag) {
          window.gtag('event', action, {
            'event_category': 'Button',
            'event_label': 'Hero Section'
          });
        }
      } catch (error) {
        console.log('Error tracking event:', error);
      }
    }
  }
}
</script>

<style scoped>
.hero {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  overflow: hidden;
  background-color: #000;
  background-image: 
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../assets/images/header_background.jpg');
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  transition: background-image 0.3s ease-in;
}
.hero-content {
  max-width: 50%;
  color: #f6f6f6;
  position: relative;
  z-index: 1;
}

.hero-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.hero-subtitle {
  font-size: 1.6rem;
  font-weight: 300;
  margin-bottom: 2rem;
}

.hero-image {
  position: absolute;
  width: 125px;
  height: 125px;
  bottom: 5%;
  right: 5%;
  z-index: 1;
}

.btn, .btn-primary {
  background-color: rgba(46, 87, 140, 1)!important;
}

@media (max-width: 768px) {
  .hero {
    height: auto;
    min-height: 100vh;
    padding: 60px 5%;
    flex-direction: column;
    text-align: center;
    background-position: center;
  }

  .hero-content {
    margin-top: 100px;
    max-width: 100%;
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .hero-image {
    position: static;
    width: 100px;
    height: 100px;
  }
}
</style>
