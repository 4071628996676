<template>
  <div id="app" class="agency-font">
    <transition name="fade" mode="out-in">
      <enclave-intro v-if="showIntro" @intro-complete="showIntro = false"></enclave-intro>
      <div v-else>
        <app-navbar></app-navbar>
        <hero-section></hero-section>
        <about-section></about-section>
        <youtube-section></youtube-section>
        <community-section></community-section>
        <!-- <video-section></video-section> -->
        <footer-section></footer-section>
      </div>
    </transition>
  </div>
</template>

<script>
import AppNavbar from './components/Navbar.vue'
import HeroSection from './components/HeroSection.vue'
import AboutSection from './components/AboutSection.vue'
import CommunitySection from './components/CommunitySection.vue'
import FooterSection from './components/FooterSection.vue'
import EnclaveIntro from './components/EnclaveIntro.vue'
// import VideoSection from './components/VideoSection.vue'
import YoutubeSection from './components/YoutubeSection.vue'

export default {
  name: 'App',
  components: {
    AppNavbar,
    HeroSection,
    AboutSection,
    CommunitySection,
    FooterSection,
    EnclaveIntro,
    // VideoSection,
    YoutubeSection
  },
  data() {
    return {
      showIntro: true
    }
  },
  methods: {
    loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    }
  },
  async mounted() {
    try {
      await this.loadScript('https://www.googletagmanager.com/gtag/js?id=G-L4YFM03F5H');
      
      window.dataLayer = window.dataLayer || [];
      window.gtag = function(){window.dataLayer.push(arguments);}
      window.gtag('js', new Date());
      window.gtag('config', 'G-L4YFM03F5H');
    } catch (error) {
      console.log('Error loading Google Analytics:', error);
      // You can add more error handling logic here, such as disabling analytics features
    }
  }
}
</script>

<style>
* {
  font-family: 'Agency Regular', sans-serif !important;
}

body, #app, .btn, input, textarea, select {
  font-family: 'Agency Regular', sans-serif !important;
}

.agency-font, .agency-font * {
  font-family: 'Agency Regular', sans-serif !important;
}

@font-face {
  font-family: 'Agency Regular';
  src: url('./assets/fonts/Agency_Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: var(--bs-body-font-family);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: linear-gradient(145deg, #394039, #0D0D0D);
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

a {
  color: #0066cc;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #004499;
}

.btn {
  display: inline-block;
  padding: 12px 24px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.btn:hover {
  background-color: #004499;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section {
  text-align: center;
}

.section-title {
  font-size: 48px;
  margin-bottom: 20px;
}

.section-subtitle {
  font-size: 24px;
  font-weight: 300;
  color: #86868b;
  max-width: 600px;
  margin: 0 auto 80px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

@media (max-width: 768px) {
  .container {
    padding: 0 15px;
  }

  .section {
    padding: 40px 0;
  }

  .section-title {
    font-size: 32px;
  }

  .section-subtitle {
    font-size: 18px;
  }

  .btn {
    padding: 10px 20px;
    font-size: 12px;
  }
}
</style>
