import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faShoppingCart, faUser, faSearch } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faInstagram, faYoutube, faDiscord } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faThumbsUp, faThumbsDown, faShare, faPlus, faEllipsisH, faSpinner } from '@fortawesome/free-solid-svg-icons'

library.add(faShoppingCart, faUser, faSearch, faFacebook, faTwitter, faInstagram, faYoutube, faDiscord)
library.add(faThumbsUp, faThumbsDown, faShare, faPlus, faEllipsisH, faSpinner)

window.onerror = function(message, source, lineno, colno, error) {
  console.log('Global error caught:', { message, source, lineno, colno, error });
  // You can add more detailed logging or error reporting here
  return true; // Prevents the error from being shown in the console
};

const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')
